<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-card
          :img-src="require('@/assets/images/banner/banner-1.jpg')"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar
                size="114"
                variant="light"
                :src="require('@/assets/images/portrait/small/avatar-s-9.jpg')"
              />
            </div>
          </div>
          <h3>Albertus Christian</h3>
          <h6 class="text-muted">
            Baby Care
          </h6>
          <hr class="mb-2">

          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">
                Omzet
              </h6>
              <h3 class="mb-0">
                42.9m
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                Sales
              </h6>
              <h3 class="mb-0">
                68
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                Members
              </h6>
              <h3 class="mb-0">
                9
              </h3>
            </div>
          </div>
          <!--/ follower projects rank -->
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card
          v-if="group.items"
          no-body
          class="card-company-table"
        >
          <b-table
            :items="group.items"
            responsive
            :fields="group.fields"
            class="mb-0"
          >
            <!-- reseller -->
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.title }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.item.subtitle }}
                  </div>
                </div>
              </div>
            </template>

            <!-- category -->
            <template #cell(category)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-1"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    :icon="data.item.avatarIcon"
                  />
                </b-avatar>
                <span>{{ data.item.avatarTitle }}</span>
              </div>
            </template>

            <!-- omzet -->
            <template #cell(omzet)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{ data.item.omzet }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { 
    BRow, BCol,
    BCard, BAvatar, BBadge, BTable
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow, BCol,
      BCard, BAvatar, BBadge, BTable
    },
    data() {
      return {
        group: {
          items: [
            {
              no: "#1",
              avatarIcon: "CoffeeIcon",
              avatarTitle: "Grocery",
              loss: true,
              subtitle: "zidane@the-netwerk.com",
              title: "M. Zidane",
              joinDate: "10 Oct 2020"
            },
            {
              no: "#2",
              avatarIcon: "CoffeeIcon",
              avatarTitle: "Grocery",
              loss: true,
              subtitle: "zahra@the-netwerk.com",
              title: "Zahra Dita",
              joinDate: "10 Oct 2020"
            },
            {
              no: "#3",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "andini@the-netwerk.com",
              title: "Andini Ismah",
              joinDate: "23 Sept 2019"
            },
            {
              no: "#4",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "ferry@the-netwerk.com",
              title: "Ferry Nara",
              joinDate: "1 Jun 2019"
            },
            {
              no: "#5",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "siahaanriksa@the-netwerk.com",
              title: "Riksa Siahaan",
              joinDate: "1 Jun 2019"
            },
            {
              no: "#6",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "tantangtang@the-netwerk.com",
              title: "Tatang Suhendra",
              joinDate: "1 Jun 2019"
            },
            {
              no: "#7",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "suebeus@the-netwerk.com",
              title: "Sueb Sukarman",
              joinDate: "1 Jun 2019"
            },
            {
              no: "#8",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "auliarani@the-netwerk.com",
              title: "Aulia Maharani",
              joinDate: "1 Jun 2019"
            },
            {
              no: "#4",
              avatarIcon: "TagIcon",
              avatarTitle: "Clothing",
              loss: false,
              subtitle: "birruss@the-netwerk.com",
              title: "Biru Syailendra",
              joinDate: "1 Jun 2019"
            },
          ],
          fields: [
            { key: 'no', label: 'NO' },
            { key: 'name', label: 'NAME' },
            { key: 'category', label: 'CATEGORY' },
            { key: 'joinDate', label: 'JOIN DATE' },
          ],
        }
      }
    },
  }
</script>
